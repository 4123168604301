import { Injectable } from "@angular/core";

@Injectable({
	providedIn: 'root'
})
export class XtrasService {

    constructor(
    ) {}

    filterEmptyParams(formData: { [key: string]: any }) {
        return Object.fromEntries(
            Object.entries(formData).filter(
                ([_, value]) => value !== null && value !== undefined && value !== ''
            )
        );
    }
}